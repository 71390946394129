var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('t-table', {
    staticClass: "shadow-none border border-gray-50 bg-white",
    attrs: {
      "data": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "thead",
      fn: function (props) {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left",
          class: props.thClass
        }, [_vm._v("No")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Customer Account")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Invent Location ID")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Account Type")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Account Subtype")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Vendor")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Amount")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Transaction Date")]), _c('th', {
          class: props.thClass
        }, [_vm._v("Period")]), _c('th', {
          class: props.thClass
        })])])];
      }
    }, !_vm.items.length ? {
      key: "tbody",
      fn: function (props) {
        return [_c('tbody', {
          class: props.tbodyClass
        }, [_c('tr', {
          class: [props.trClass, 'text-center']
        }, [_c('td', {
          class: props.tdClass,
          attrs: {
            "colspan": "15"
          }
        }, [_c('div', {
          staticClass: "flex-col items-center justify-center flex-nowrap flex h-96"
        }, [_c('svg', {
          staticClass: "w-16 h-16 text-gray-800 dark:text-white",
          attrs: {
            "aria-hidden": "true",
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "24",
            "height": "24",
            "fill": "currentColor",
            "viewBox": "0 0 24 24"
          }
        }, [_c('path', {
          attrs: {
            "d": "M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z"
          }
        })]), _c('h4', {
          staticClass: "text-base text-gray-600 mb-0"
        }, [_vm._v("Select Filter to Display")]), _c('p', {
          staticClass: "text-sm text-gray-500"
        }, [_vm._v("Your data will be displayed once the filter has been selected.")])])])])])];
      }
    } : null, {
      key: "row",
      fn: function (props) {
        return [_c('tr', {
          staticClass: "border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3 hover:bg-gray-50"
        }, [_c('td', {
          staticClass: "pl-2",
          class: props.tdClass
        }, [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + props.rowIndex + 1) + " ")]), _c('td', {
          class: props.tdClass
        }, [_vm._v(_vm._s(_vm.accountID))]), _c('td', {
          class: props.tdClass
        }, [_vm._v(_vm._s(_vm.inventLocationId))]), _c('td', {
          class: props.tdClass
        }, [_vm._v(_vm._s(_vm.accountType))]), _c('td', {
          class: props.tdClass
        }, [props.row.offer_type === 'single' ? _c('div', [_c('span', {
          staticClass: "bg-blue-100 text-blue-500 p-1 rounded"
        }, [_vm._v(" " + _vm._s(props.row.offer_type) + " ")])]) : props.row.offer_type === 'bundle' ? _c('div', [_c('span', {
          staticClass: "bg-yellow-100 text-yellow-500 p-1 rounded"
        }, [_vm._v(" " + _vm._s(props.row.offer_type) + " ")])]) : props.row.offer_type === 'subscription' ? _c('div', [_c('span', {
          staticClass: "bg-red-100 text-red-500 p-1 rounded"
        }, [_vm._v(" " + _vm._s(props.row.offer_type) + " ")])]) : props.row.offer_type === 'buffet' ? _c('div', [_c('span', {
          staticClass: "bg-purple-100 text-purple-500 p-1 rounded"
        }, [_vm._v(" " + _vm._s(props.row.offer_type) + " ")])]) : _vm._e()]), _c('td', {
          class: props.tdClass
        }, [_vm._v(_vm._s(props.row.vendor_name))]), _c('td', {
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.isGroup ? props.row.total : props.row.disbursement, "IDR")) + " ")]), _c('td', {
          class: props.tdClass
        }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.isGroup ? props.row.disbursement_period : props.row.disbursement_date)) + " ")]), _c('td', {
          class: props.tdClass
        }, [_vm._v(_vm._s(_vm.formatDate(props.row.disbursement_period)))]), _c('td', {
          class: props.tdClass
        }, [_c('t-dropdown', {
          scopedSlots: _vm._u([{
            key: "trigger",
            fn: function (_ref) {
              var mousedownHandler = _ref.mousedownHandler,
                focusHandler = _ref.focusHandler,
                blurHandler = _ref.blurHandler,
                keydownHandler = _ref.keydownHandler,
                isShown = _ref.isShown;
              return _c('div', {}, [_c('button', {
                staticClass: "cursor-pointer focus:outline-none",
                class: {
                  ' text-gray-500 ': isShown
                },
                attrs: {
                  "id": "user-menu",
                  "aria-label": "User menu",
                  "aria-haspopup": "true"
                },
                on: {
                  "focus": function ($event) {
                    $event.stopPropagation();
                    return focusHandler.apply(null, arguments);
                  },
                  "blur": function ($event) {
                    $event.stopPropagation();
                    return blurHandler.apply(null, arguments);
                  },
                  "keydown": function ($event) {
                    $event.stopPropagation();
                    return keydownHandler.apply(null, arguments);
                  },
                  "click": function ($event) {
                    $event.stopPropagation();
                    return mousedownHandler.apply(null, arguments);
                  }
                }
              }, [_c('i', {
                staticClass: "fa-solid fa-ellipsis"
              })])]);
            }
          }, {
            key: "default",
            fn: function (_ref2) {
              var hide = _ref2.hide,
                blurHandler = _ref2.blurHandler;
              return _c('div', {}, [_c('button', {
                staticClass: "block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100",
                attrs: {
                  "role": "menuitem"
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return hide.apply(null, arguments);
                  },
                  "blur": blurHandler
                }
              }, [_vm.isGroup ? _c('router-link', {
                staticClass: "block w-full px-0 py-1 text-sm text-gray-600",
                attrs: {
                  "to": {
                    path: ("/subledger/vendor/" + (props.row.vendor_id) + "?offer_type=" + (props.row.offer_type) + "&period=" + (props.row.disbursement_period))
                  }
                }
              }, [_c('div', {
                staticClass: "flex justify-self-start"
              }, [_c('div', {
                staticClass: "flex justify-between items-center"
              }, [_c('svg', {
                staticClass: "w-6 h-6 text-gray-500 dark:text-white",
                attrs: {
                  "aria-hidden": "true",
                  "xmlns": "http://www.w3.org/2000/svg",
                  "width": "24",
                  "height": "24",
                  "fill": "none",
                  "viewBox": "0 0 24 24"
                }
              }, [_c('path', {
                attrs: {
                  "stroke": "currentColor",
                  "stroke-width": "2",
                  "d": "M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                }
              }), _c('path', {
                attrs: {
                  "stroke": "currentColor",
                  "stroke-width": "2",
                  "d": "M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                }
              })]), _c('span', {
                staticClass: "text-gray-500 ml-1 hover:no-underline"
              }, [_vm._v("View")])])])]) : _vm._e()], 1), _c('button', {
                staticClass: "block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100",
                attrs: {
                  "role": "menuitem"
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return hide.apply(null, arguments);
                  },
                  "blur": blurHandler
                }
              }, [_c('div', {
                staticClass: "flex justify-start"
              }, [_c('div', {
                staticClass: "flex justify-between items-center",
                on: {
                  "click": function ($event) {
                    return _vm.onDownload(props.row, props.rowIndex);
                  }
                }
              }, [_vm.isDownload && props.rowIndex === _vm.current_row ? _c('div', {
                staticClass: "animate-spin text-blue-500 text-base"
              }, [_c('i', {
                staticClass: "fa-solid fa-circle-notch fa-flip-horizontal"
              })]) : _c('svg', {
                staticClass: "w-6 h-6 text-gray-500 dark:text-white",
                attrs: {
                  "aria-hidden": "true",
                  "xmlns": "http://www.w3.org/2000/svg",
                  "width": "",
                  "height": "24",
                  "fill": "currentColor",
                  "viewBox": "0 0 24 24"
                }
              }, [_c('path', {
                attrs: {
                  "fill-rule": "evenodd",
                  "d": "M13 11.15V4a1 1 0 1 0-2 0v7.15L8.78 8.374a1 1 0 1 0-1.56 1.25l4 5a1 1 0 0 0 1.56 0l4-5a1 1 0 1 0-1.56-1.25L13 11.15Z",
                  "clip-rule": "evenodd"
                }
              }), _c('path', {
                attrs: {
                  "fill-rule": "evenodd",
                  "d": "M9.657 15.874 7.358 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.358l-2.3 2.874a3 3 0 0 1-4.685 0ZM17 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z",
                  "clip-rule": "evenodd"
                }
              })]), _c('span', {
                staticClass: "text-gray-500 ml-1 hover:no-underline"
              }, [_vm._v("Download")])])])])]);
            }
          }], null, true)
        })], 1)])];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }