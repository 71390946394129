<template>
  <div class="">
    <t-table :data="items" class="shadow-none border border-gray-50 bg-white">
      <template v-slot:thead="props">
        <thead>
          <tr>
            <th :class="props.thClass" class="text-left">No</th>
            <th :class="props.thClass">Customer Account</th>
            <th :class="props.thClass">Invent Location ID</th>
            <th :class="props.thClass">Account Type</th>
            <th :class="props.thClass">Account Subtype</th>
            <th :class="props.thClass">Vendor</th>
            <th :class="props.thClass">Amount</th>
            <th :class="props.thClass">Transaction Date</th>
            <th :class="props.thClass">Period</th>
            <th :class="props.thClass"></th>
          </tr>
        </thead>
      </template>
      <template v-if="!items.length" v-slot:tbody="props">
        <tbody :class="props.tbodyClass">
          <tr :class="[props.trClass, 'text-center']">
            <td :class="props.tdClass" colspan="15">
              <div class="flex-col items-center justify-center flex-nowrap flex h-96">
                <svg class="w-16 h-16 text-gray-800 dark:text-white" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z" />
                </svg>

                <h4 class="text-base text-gray-600 mb-0">Select Filter to Display</h4>
                <p class="text-sm text-gray-500">Your data will be displayed once the filter has been selected.</p>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template slot="row" slot-scope="props">
        <tr class="border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3 hover:bg-gray-50">
          <td :class="props.tdClass" class="pl-2">
            {{ (currentPage - 1) * perPage + props.rowIndex + 1 }}
          </td>
          <td :class="props.tdClass">{{ accountID }}</td>
          <td :class="props.tdClass">{{ inventLocationId }}</td>
          <td :class="props.tdClass">{{ accountType }}</td>
          <td :class="props.tdClass">
            <div v-if="props.row.offer_type === 'single'">
              <span class="bg-blue-100 text-blue-500 p-1 rounded">
                {{ props.row.offer_type }}
              </span>
            </div>
            <div v-else-if="props.row.offer_type === 'bundle'">
              <span class="bg-yellow-100 text-yellow-500 p-1 rounded">
                {{ props.row.offer_type }}
              </span>
            </div>
            <div v-else-if="props.row.offer_type === 'subscription'">
              <span class="bg-red-100 text-red-500 p-1 rounded">
                {{ props.row.offer_type }}
              </span>
            </div>
            <div v-else-if="props.row.offer_type === 'buffet'">
              <span class="bg-purple-100 text-purple-500 p-1 rounded">
                {{ props.row.offer_type }}
              </span>
            </div>
          </td>
          <td :class="props.tdClass">{{ props.row.vendor_name }}</td>
          <td :class="props.tdClass">
            {{ formatPrice(isGroup ? props.row.total : props.row.disbursement, "IDR") }}
          </td>
          <td :class="props.tdClass">
            {{ formatDate(isGroup ? props.row.disbursement_period : props.row.disbursement_date) }}
          </td>
          <td :class="props.tdClass">{{ formatDate(props.row.disbursement_period) }}</td>
      
          <td :class="props.tdClass">

            <t-dropdown>
              <div slot="trigger" slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown
              }">
                <button id="user-menu" :class="{ ' text-gray-500 ': isShown }" aria-label="User menu"
                  aria-haspopup="true" @focus.stop="focusHandler" 
                  @blur.stop="blurHandler"
                  @keydown.stop="keydownHandler" 
                  @click.stop="mousedownHandler"
                  class="cursor-pointer focus:outline-none">
                  <i class="fa-solid fa-ellipsis"></i>
                </button>
              </div>

              <div slot-scope="{ hide, blurHandler }">
                <button
                  class="block text-left  w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" 
                  @click.stop="hide" 
                  @blur="blurHandler"
                  >
                  <router-link v-if="isGroup"
                    :to="{ path: `/subledger/vendor/${props.row.vendor_id}?offer_type=${props.row.offer_type}&period=${props.row.disbursement_period}` }"
                    class="block w-full px-0 py-1 text-sm text-gray-600">
                    <div class="flex justify-self-start">
                      <div class="flex justify-between items-center">
                        <svg class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-width="2"
                            d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                          <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                        <span class="text-gray-500 ml-1 hover:no-underline">View</span>
                      </div>
                    </div>
                  </router-link>

                </button>
                <button
                  class="block text-left w-full px-4 py-2 text-sm leading-5 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem" @click.stop="hide" @blur="blurHandler">

                  <div class="flex justify-start">
                    <div class="flex justify-between items-center" @click="onDownload(props.row, props.rowIndex)">
                      <div  v-if="isDownload && props.rowIndex === current_row" class="animate-spin text-blue-500 text-base">
                      <i class="fa-solid fa-circle-notch fa-flip-horizontal"></i>
                    </div>
                      <svg v-else class="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="" height="24" fill="currentColor"
                        viewBox="0 0 24 24">
                        <path fill-rule="evenodd"
                          d="M13 11.15V4a1 1 0 1 0-2 0v7.15L8.78 8.374a1 1 0 1 0-1.56 1.25l4 5a1 1 0 0 0 1.56 0l4-5a1 1 0 1 0-1.56-1.25L13 11.15Z"
                          clip-rule="evenodd" />
                        <path fill-rule="evenodd"
                          d="M9.657 15.874 7.358 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.358l-2.3 2.874a3 3 0 0 1-4.685 0ZM17 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z"
                          clip-rule="evenodd" />
                      </svg>

                      <span class="text-gray-500 ml-1 hover:no-underline">Download</span>
                    </div>

                  </div>
                </button>

              </div>
            </t-dropdown>
          </td>
        </tr>
      </template>
    </t-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  name: 'SubledgerTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isGroup: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    totalRows: {
      type: Number,
      default: 0
    },
    accountID: {
      type: String,
      default: "C-002829808"
    },
    inventLocationId: {
      type: String,
      default: "10198"
    },
    accountType: {
      type: String,
      default: "Revenue"
    },
    perPage: {
      type: Number,
      default: 10

    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      fields: [
        {
          key: "vendor_name",
          label: "Vendor",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "offer_type",
          label: "Report Type",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "total",
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isDownload: (state) => state.subledger.isDownload,
    }),
  },
  methods: {
    ...mapActions('subledger', ['sendReportByPeriod']),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    onDownload(row, idx) {
      console.log('rowData', row);
      this.current_row = idx;

      const payload = {
        vendor_id: row.vendor_id,
        period: moment(row.disbursement_period).format("YYYY-MM-DD"),
      }
     this.sendReportByPeriod(payload);
    },
  },

}
</script>

<style lang="css" scoped>
.table-fn {
  font-family: "Avenir Next"
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s infinite linear;
}
</style>