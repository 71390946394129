<template>
  <div class="rows books">
    <div class="bg-white p-4  my-4 rounded-lg shadow-sm">
      <label>Subledger</label>
      <h4 class="text-2xl font-semibold">Subledger</h4>
    </div>

    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <div class="flex justify-between">
        <div class="w-full">
          <div class="grid grid-cols-5 gap-4">
            <div>
              <div class="block">
                <span class="font-semibold">Period Date</span>
                <DatetimeCustom :label="''" :isPeriodMonth="true" :timeDate="false" v-model="range.start">
                </DatetimeCustom>
              </div>
            </div>
            <div>
              <div class="block">
                <span class="font-semibold block mb-3">Ledger</span>
                <select class="rounded-lg py-2.5 border w-full" v-model="Filter.ledger">
                  <option value="revenue">Revenue</option>
                  <option value="payment-gateway" disabled>PaymentsGateway</option>
                </select>
              </div>
            </div>
            <div>
              <div class="block">
                <span class="font-semibold block mb-3">Account Subtype</span>
                <select v-model="Filter.offer_type" class="rounded-lg py-2.5 border w-full">
                  <option key=""> ALL</option>
                  <option v-for="offer in Offers" :key="offer.value" :value="offer.name">
                    {{ offer.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-2">
              <div class="block w-full">
                <span class="font-semibold block mb-3">Publisher</span>
                <multiselect v-model="Filter.vendor_id" :options="vendors" placeholder="Select one" label="Vendor Name"
                  :close-on-select="true" :custom-label="customLabel" @search-change="asyncFind" :searchable="true"
                  :loading="isLoadingSearch" track-by="name">
                </multiselect>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
        <div class="w-1/12 flex justify-end">
          <div class="block mt-9 ml-3">
            <button class="rounded-lg bg-blue-500 py-2.5 px-3 text-white" @click="actionSearch">
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white rounded-lg py-4 px-4 ">
      <div v-if="isLoading" role="status" class="flex justify-center items-center h-72">
        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 text-center animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill" />
        </svg>
        <span class="sr-only text-center items-center">Loading...</span>
      </div>
      <div v-if="!isLoading && financeReport && items.length">
        <div class="flex justify-between finance-report">
          <div v-for="(report, title_report) in financeReport" :key="title_report"
            class=" overflow-hidden border rounded-xl w-full">
            <div>
              <div class="flex justify-between items-center bg-gray-50 py-2.5 px-2.5 w-full">
                <div>
                  <h3 class="font-bold text-base mb-0 uppercase">{{ title_report }}</h3>
                </div>
                <div>
                  <h3 class="font-bold text-base mb-0 uppercase text-blue-700"> {{ report.total | currency }}</h3>
                </div>
              </div>
              <div class="flex justify-between items-center py-2.5 px-2.5 w-full">
                <div>
                  <p>Pend. GD</p>
                  <p>Pend. Publisher</p>
                  <p>Pend. Gateway</p>
                </div>
                <div>
                  <p> {{ report.gd | currency }}</p>
                  <p>{{ report.publisher | currency }}</p>
                  <p>{{ report.pg | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="py-4">
        <TableSubledger :items="items" :totalRows="parseInt(totalRows)" :isLoading="isLoading" :isGroup="true"
          :perPage="perPage" :currentPage="currentPage" />
      </div>
      <div v-if="!isLoading" class="flex bg-white justify-between pl-0 pr-3 pt-4 mb-0 border-t border-gray-100 mt-1">
        <div class="font-semibold">
          <span class="text-gray-500 font-normal">Showing </span> {{ currentPage }}-{{ perPage }} <span
            class="text-gray-500 font-normal">of</span> {{ parseInt(totalRows) }}
        </div>
        <div class="mb-2">
          <t-pagination :total-items="Number(totalRows)" :per-page="perPage" v-model="currentPage"
            @change="onClickPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableSubledger from "./table.vue"
import DatetimeCustom from '../../components/form/Datetime.vue'
import moment from "moment";
export default {
  name: 'Subledger',
  components: {
    TableSubledger,
    DatetimeCustom
  },
  data() {
    return {
      range: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD'),
      },

      dates: null,
      masks: {
        input: "YYYY-MM-DD",
      },
      Filter: {
        vendor_id: '',
        offer_type: '',
      },
      Offers: [
        {
          name: "Buffet",
          value: "Buffet"
        },
        {
          name: "Single",
          value: "Single"
        }, {
          name: "Subscription",
          value: "Subscription"
        }, {
          name: "Bundle",
          value: "Bundle"
        }
      ],
      perPage: 50,
      currentPage: 1,
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.subledger.items,
      financeReport: (state) => state.subledger.financeReport,
      isLoading: (state) => state.subledger.isLoading,
      isDownload: (state) => state.subledger.isDownload,
      successMessage: (state) => state.subledger.successMessage,
      errorMessage: (state) => state.subledger.errorMessage,
      totalRows: (state) => state.subledger.totalRows,
      vendors: (state) => state.vendors.items,
      isLoadingSearch: (state) => state.vendors.isLoading,
    })
  },
  watch: {
    currentPage: function () {
      this.actionSearch()
    },
    'range.start': function (newStart) {
      this.range.end = moment(newStart).endOf('month').format('YYYY-MM-DD');
    },
    errorMessage: function () {
      if (this.errorMessage) {
        this.messageAlert('error', this.errorMessage);
      }
    },
    isDownload: function () {
      if (!this.isDownload && this.successMessage) {
        this.messageAlert('success', this.successMessage);
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Subsidiary Ledger | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  created() {
    this.checkQueryParams();
  },
  methods: {
    //
    ...mapActions('subledger', ['fetchReport', 'sendReportByPeriod', 'fetchFinanceReport']),
    ...mapActions("vendors", ["fetchVendors", "searchVendors"]),
    validateDate() {
      console.log(this.range)
      if (!this.range.start || !this.range.end) {
        this.$swal({
          toast: "true",
          position: "top-end",
          icon: "error",
          title: "Date range is required",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return false;
      } else {
        return true;
      }
    },
    extractQuery() {
      let query = this.$route.query
      if (query) {
        return {
          from: moment(query.from).format("YYYY-MM-DD"),
          to: moment(query.to).format("YYYY-MM-DD"),
          page: this.currentPage,
          limit: this.perPage,
          vendor_id: query.vendor_id ? query.vendor_id.id : false,
          offer_type: query.offer_type ? query.offer_type : false,
        };

      }
      return {}
    },
    actionSearch() {
      let v = this.validateDate()
      let query = this.extractQuery()
      if (v) {

        let payload = {
          from: moment(this.range.start).format("YYYY-MM-DD"),
          to: moment(this.range.end).format("YYYY-MM-DD"),
          page: this.currentPage,
          limit: this.perPage,
          vendor_id: this.Filter.vendor_id ? this.Filter.vendor_id.id : false,
          offer_type: this.Filter.offer_type ? this.Filter.offer_type : false,
        };
        let payloadForFetchFinanceReport = { ...payload };
        delete payloadForFetchFinanceReport.page;
        delete payloadForFetchFinanceReport.limit;
        let q
        if (payload.vendor_id && payload.offer_type) {
          q = { from: payload.from, to: payload.to, vendor_id: payload.vendor_id, offer_type: payload.offer_type }
        } else if (payload.offer_type) {
          q = { from: payload.from, to: payload.to, offer_type: payload.offer_type }
        } else if (payload.vendor_id) {
          q = { from: payload.from, to: payload.to, vendor_id: payload.vendor_id }
        } else {
          q = { from: payload.from, to: payload.to }
        }
        if (JSON.stringify(query) != JSON.stringify(payload)) {
          this.fetchReport(payload)
          this.fetchFinanceReport(payloadForFetchFinanceReport)
          this.$router.push({ path: 'subledger', query: q })
        } else {
          this.fetchReport(payload)
          this.fetchFinanceReport(payloadForFetchFinanceReport)
        }

      }
    },
    checkQueryParams() {
      const from = this.$route.query.from;
      const to = this.$route.query.to;

      if (from && to) {
        this.range.start = from;
        this.range.end = to;
      }
      this.actionSearch()
    },
    actionGetVendor() {
      let payload = {
        page: 1,
        limit: 1000,
      }
      this.fetchVendors(payload)
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    existQuery() {
      let q = this.$route.query
      if (q) {
        this.range.start = new Date(q.from).toString()
        this.range.end = new Date(q.to).toString()
      }
      if (q.from && q.to && q.vendor_id && q.offer_type) {
        let payload = {
          from: q.from,
          to: q.to,
          page: 1,
          limit: this.perPage,
          vendor_id: q.vendor_id ? q.vendor_id : false,
          offer_type: q.offer_type ? q.offer_type : false,
        };
        let offer_type = this.Offers.find(v => v.value == q.offer_type)
        let vendor_id = this.vendors.find(v => v.id == q.vendor_id)

        this.Filter.offer_type = offer_type.value
        this.Filter.vendor_id = vendor_id
        this.range.start = q.from
        this.range.end = q.to
        this.fetchReport(payload)
      } else if (q.from && q.to && q.vendor_id && !q.offer_type) {
        let payload = {
          from: q.from,
          to: q.to,
          page: 1,
          limit: this.perPage,
          vendor_id: q.vendor_id,
          offer_type: q.offer_type ? q.offer_type : false,
        };
        this.Filter.vendor_id = this.vendors.find(v => v.id == q.vendor_id)
        this.fetchReport(payload)
      } else if (q.from && q.to && q.offer_type && !q.vendor_id) {
        let payload = {
          from: q.from,
          to: q.to,
          page: 1,
          limit: this.perPage,
          vendor_id: q.vendor_id ? q.vendor_id : false,
          offer_type: q.offer_type,
        };
        this.Filter.offer_type = this.Offers.find(v => v.value == q.offer_type)
        this.fetchReport(payload)
      } else if (q.from && q.to) {
        let payload = {
          from: q.from,
          to: q.to,
          page: 1,
          limit: this.perPage,
          vendor_id: q.vendor_id ? q.vendor_id : false,
          offer_type: q.offer_type,
        };

        this.fetchReport(payload)
      }
    },
    downloadReport() {
      if (!this.Filter.vendor_id) {
        this.messageAlert("error", "Publisher is required");
        return false;
      }
      const payload = {
        vendor_id: this.Filter.vendor_id.id,
        offer_type: (this.Filter.offer_type ?? '').toLowerCase(),
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
      }
      this.sendReportByPeriod(payload);
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    onClickPage(page) {
      this.currentPage = page;
      // this.reloadPage();
    },
    asyncFind(query) {
      if (query.length > 3) {
        this.isLoadingSearch = true;
        setTimeout(() => {
          let payload = {
            q: query,
            limit: 10
          };
          this.searchVendors(payload)
        }, 800);
      }
    }
  },
  filters: {
    currency(value) {
      if (!value) return '0';
      return new Intl.NumberFormat('en-ID', {
        style: 'currency',
        currency: 'IDR'
      }).format(value);
    }
  }
}
</script>
<style scoped>
.finance-report>div {
  margin-right: 20px;
}

.finance-report>div:last-child {
  margin-right: 0px;

}
</style>